<template>
  <div class="">
    <el-menu
        :default-openeds="opens" style="min-height: 100vh; overflow-x: hidden"
        background-color="#111218"
        text-color="#fff"
        active-text-color="#ffd04b"
        :collapse-transition="false"
        :collapse="isCollapse"
        :default-active="$route.path"
        router
        @select="handleSelect">
      <div class="head-logo" style="height: 56px; line-height: 56px; text-align: center">
        <b class="color-primary">后台管理中心</b>
      </div>
      <el-menu-item v-for="item in menuList" :key="item.path" :index="item.path" @click="updateLabel(item.name)">
        <template slot="title">
          <el-image v-show="$route.path !== item.path" :src="item.icon" class="icon"></el-image>
          <el-image v-show="$route.path === item.path" :src="item.iconChecked" class="icon"></el-image>
          <span slot="title" class="title">{{ item.name }}</span>
        </template>
      </el-menu-item>
    </el-menu>
  </div>
</template>
<script>
// import settingIcon from '/src/assets/icon/menu/icon-setting.png'
// import settingIconChecked from '/src/assets/icon/menu/icon-setting-checked.png'
import goodsIcon from '/src/assets/icon/menu/icon-goods.png'
import goodsIconChecked from '/src/assets/icon/menu/icon-goods-checked.png'
import cateIcon from '/src/assets/icon/menu/icon-cate.png'
import cateIconChecked from '/src/assets/icon/menu/icon-cate-checked.png'
import bannerIcon from '/src/assets/icon/menu/icon-banner.png'
import bannerIconChecked from '/src/assets/icon/menu/icon-banner-chekced.png'
import positionIcon from '/src/assets/icon/menu/icon-position.png'
import positionIconChecked from '/src/assets/icon/menu/icon-position-checked.png'
import noticeIcon from '/src/assets/icon/menu/ic_message.png'
import noticeIconChecked from '/src/assets/icon/menu/ic_message-checked.png'

export default {
  name: "ComAside",
  data() {
    return {
      isCollapse: false,
      opens: [],
      menuList: [
        {
          name: '用户列表',
          checked: true,
          path: '/user',
          icon: positionIcon,
          iconChecked: positionIconChecked,
        },
        {
          name: '商品列表',
          checked: true,
          path: '/goods',
          icon: goodsIcon,
          iconChecked: goodsIconChecked,
        },
        {
          name: '分类列表',
          checked: false,
          path: '/cate',
          icon: cateIcon,
          iconChecked: cateIconChecked,
        },
        {
          name: '首页轮播',
          checked: false,
          path: '/banner',
          icon: bannerIcon,
          iconChecked: bannerIconChecked,
        },
        {
          name: '首页通知',
          checked: false,
          path: '/notice',
          icon: noticeIcon,
          iconChecked: noticeIconChecked,
        }
        // {
        //   name: '账号设置',
        //   checked: false,
        //   path: '/setting',
        //   icon: settingIcon,
        //   iconChecked: settingIconChecked,
        // }
      ]
    }
  },
  methods: {
    handleSelect() {
    },
    updateLabel(name) {
      this.$store.commit('menu/updateLabel', name)
    }
  }
}
</script>

<style lang="scss" scoped>
.head-logo {
  background-color: #1f1f25;
  // color: #ffffff;

  .color-primary {
    background-color: #ffffff;
    padding: 6px 16px;
    border-radius: 4px;
  }
}

.icon {
  color: #ffffff;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.title {
  font-size: 15px;
  font-weight: 500;
}

/deep/ .el-menu {
  border-right: none;
}

.el-menu-item {
  color: #cccccc !important;
}

.el-menu-item.is-active {
  color: #FFFFFF !important;
}

.el-menu-item {
  &:hover {
    background-color: #47474C !important;
    color: #FFFFFF !important;
  }
}

.el-menu-item.is-active {
  background-color: #4F6FF0 !important;
}
</style>
