const state = () => {
    return {
        label: '',
    }
}

const mutations = {
    updateLabel(state, data) {
        state.label = data
    }
}

const actions = {
    setLabel({commit}, name) {
        commit('updateLabel', name)
    },
}

export default {
    namespaced: true, state, mutations, actions
}
