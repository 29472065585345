import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Layout from '@/layout'

const router = new Router({
    routes: [
        {
            path: '/', redirect: '/user'
        }, {
            path: '/user',
            component: Layout,
            redirect: '/user',
            children: [{path: '/user', name: '用户列表', component: () => import('@/views/user')}]
        }, {
            path: '/goods',
            component: Layout,
            redirect: '/goods',
            children: [{path: '/goods', name: '商品列表', component: () => import('@/views/goods/goods')}]
        }, {
            path: '/cate',
            component: Layout,
            redirect: '/cate',
            children: [{path: '/cate', name: '分类列表', component: () => import('@/views/cate/cate')}]
        }, {
            path: '/banner',
            component: Layout,
            redirect: '/banner',
            children: [{path: '/banner', name: '首页轮播', component: () => import('@/views/banner/banner')}]
        }, {
            path: '/notice',
            component: Layout,
            redirect: '/notice',
            children: [{path: '/notice', name: '首页通知', component: () => import('@/views/notice/notice')}]
        }, {
            path: '/setting', component: Layout, redirect: '/setting', children: [{
                path: '/setting', name: '账号设置', component: () => import('@/views/setting')
            }]
        }, {
            path: '/login', component: () => import('@/views/login/login'),
        }, {
            path: '/404', component: () => import('@/views/404'), hidden: true
        }, {
            path: '*', redirect: '/404', hidden: true
        }]
})
export default router;
