import Vue from 'vue'
import Vuex from 'vuex'
import menu from "@/store/modules/menu";
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

// 用来存储数据
const state = {}
// 响应组件中的事件
const actions = {}
// 操作数据
const mutations = {}
// 用来将state数据进行加工
const getters = {}

// 新建并暴露store
const store = new Vuex.Store({
    modules: {
        menu
    },
    state,
    actions,
    mutations,
    getters,
    plugins: [
        createPersistedState({
            // 存储方式：localStorage、sessionStorage、cookies
            storage: window.sessionStorage,
            key: "store",
            render(state) {
                return {...state};
            }
        })
    ]
})

export default store