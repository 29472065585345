import Vue from 'vue'
import App from './App.vue'

import '@/assets/css/global.scss'
import store from "@/store";

import VueRouter from 'vue-router'
import router from "@/router";

import '@/styles/index.scss'

Vue.use(VueRouter);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI, {size: 'medium'});

import Vuex from 'vuex'

Vue.use(Vuex)

Vue.filter('cdn', (value) => {
    if (value) {
        return 'https://video.lovevip.vip/' + value
    }
})

Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
