<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {}
}
</script>

<style lang="scss">
@import "/src/assets/css/main.css";

/* 富文本菜单层级 */
.tox-tinymce-aux {
  z-index: 9999 !important;
}
</style>
