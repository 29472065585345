<template>
  <el-container style="min-height: 100vh;">
    <!--侧边栏-->
    <el-aside :width="sideWidth + 'px'">
      <Aside :isCollapse="isCollapse" :logoTextShow="logoTextShow"/>
    </el-aside>
    <!--头部-->
    <el-container>
      <el-header style="height: 56px; box-shadow: 0 2px 4px rgba(0,0,0,.08); padding: 0;">
        <Header :collapse-btn-class="collapseBtnClass" :asideCollapse="collapse" :user="user"/>
      </el-header>

      <!--<el-main class="app-main">-->
      <!--  <router-view @refreshUser="getUser"/>-->
      <!--</el-main>-->

      <section class="app-main">
        <transition name="fade-transform" mode="out-in">
          <router-view :key="key"/>
        </transition>
      </section>
    </el-container>
  </el-container>
</template>

<script>
import Aside from "./components/Aside";
import Header from "./components/Header";

export default {
  name: "LayIndex",
  data() {
    return {
      collapseBtnClass: 'el-icon-s-fold',
      isCollapse: false,
      sideWidth: 160,
      logoTextShow: true,
      user: {},
    }
  },
  computed: {
    key() {
      return this.$route.path
    }
  },
  components: {
    Aside,
    Header,
  },
  created() {
    //从后台获取最新的User数据
    this.getUser()
  },
  methods: {
    collapse() {//点击收缩按钮触发
      this.isCollapse = !this.isCollapse
      if (this.isCollapse) {       //收缩
        this.sideWidth = 64
        this.collapseBtnClass = 'el-icon-s-unfold'
        this.logoTextShow = false
      } else {//展开
        this.sideWidth = 200
        this.collapseBtnClass = 'el-icon-s-fold'
        this.logoTextShow = true
      }
    },
    getUser() {
      // let username = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).username : ""
      //从后台获取User数据
      // this.request.get("user/username/" + username).then(res => {
      //   //从新赋值后台的最新User数据
      //   this.user = res.data
      // })
    }
  }
}
</script>
<style>
.app-main {
  min-height: calc(100vh - 86px);
  //width: 100%;
  position: relative;
  overflow: hidden;
  background: #F1F2F6;
  padding: 15px;
}

</style>